import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap

//i18n
import { withTranslation } from "react-i18next";
import "./header.scss";
import "../../assets/vendor/hs-mega-menu/src/css/hs-mega-menu.css";
import Logo from "../../assets/img/others/Logo1.png";
import HSMegaMenu from "../../assets/vendor/hs-mega-menu/src/js/hs-mega-menu";
import { ReactComponent as Bars } from "../../assets/icon/svgs/solid/bars.svg";
import { ReactComponent as Xmark } from "../../assets/icon/svgs/solid/xmark.svg";
import { ReactComponent as Down } from "../../assets/icon/svgs/solid/chevron-down.svg";
import { ReactComponent as Up } from "../../assets/icon/svgs/solid/chevron-up.svg";
import { ReactComponent as Vector } from "../../assets/svg/Vector11.svg";

const Header = (props) => {
  const history = useHistory();
  const megaMenuRef = useRef(null);
  const megaMenuRef1 = useRef(null);
  const [subOpen, setSubOpen] = useState(false);
  const [subReviewOpen, setSubReviewOpen] = useState(false);

  useEffect(() => {
    // DOM 요소가 준비된 후 HSMegaMenu 초기화
    if (megaMenuRef.current) {
      new HSMegaMenu(megaMenuRef.current, {
        desktop: {
          position: "left",
        },
        // HSMegaMenu에 필요한 추가 옵션
      });
    }
    if (megaMenuRef1.current) {
      new HSMegaMenu(megaMenuRef1.current, {
        desktop: {
          position: "left",
        },
        // HSMegaMenu에 필요한 추가 옵션
      });
    }
  }, []); // 빈 배열을 넘겨 컴포넌트 마운트 시 1회만 실행되도록 함

  useEffect(() => {
    // 상태가 변경될 때 부모 컴포넌트의 콜백 함수 호출
    props.onToggleSubmenu();
  }, [subOpen]); // subOpen 상태를 의존성 배열에 추가

  useEffect(() => {
    // 상태가 변경될 때 부모 컴포넌트의 콜백 함수 호출
    props.onToggleSubmenu();
  }, [subReviewOpen]); // subOpen 상태를 의존성 배열에 추가

  return (
    <React.Fragment>
      <header
        id="header"
        className="navbar navbar-expand-lg navbar-end navbar-light bg-white p-0 containerBorder"
        style={{ position: "fixed", width: "100%" }}
      >
        <div className="container " style={{ height: "100%" }}>
          <nav ref={megaMenuRef} className="js-mega-menu navbar-nav-wrap">
            <a
              className="navbar-brand d-flex align-items-center"
              href="/"
              aria-label="Front"
            >
              <img src={Logo} alt="Logo" style={{ height: "68px" }} />
              <h2 className="headerMainNameTitle mb-0">우리의 인연</h2>
            </a>
            <div
              className="collapse navbar-collapse"
              id="navbarNavDropdown"
              style={{ order: "0" }}
            >
              <ul className="navbar-nav d-flex justify-content-center">
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "200px" }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="companyMegaMenu"
                      className={
                        window.location.pathname === "/introduce"
                          ? "hs-mega-menu-invoker nav-link headerTextActive  2"
                          : "hs-mega-menu-invoker nav-link headerText 2"
                      }
                      href="/introduce"
                      role="button"
                      aria-expanded="false"
                    >
                      우연은?
                    </a>
                    <div
                      className="hs-sub-menu"
                      aria-labelledby="companyMegaMenu"
                    ></div>
                  </li>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "200px" }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="companyMegaMenu"
                      className={
                        window.location.pathname === "/problem" ||
                        window.location.pathname === "/whypage" ||
                        window.location.pathname === "/products/list"
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      서비스소개{" "}
                      <Down
                        width={11}
                        className={
                          window.location.pathname === "/problem" ||
                          window.location.pathname === "/whypage" ||
                          window.location.pathname === "/products/list"
                            ? "downSvgActive"
                            : "downSvg"
                        }
                      />
                    </a>
                    <div
                      className="hs-sub-menu dropdown-menu"
                      aria-labelledby="companyMegaMenu"
                    >
                      <a className="dropdown-item" href="/problem">
                        기존의 문제점
                      </a>
                      <a className="dropdown-item" href="/whypage">
                        왜 우연인가?
                      </a>
                      <a className="dropdown-item" href="/products/list">
                        서비스 안내
                      </a>
                    </div>
                  </li>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "200px" }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="reviewMegaMenu"
                      className={
                        window.location.pathname === "/photoreview" ||
                        window.location.pathname === "/party/review"
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      후기{" "}
                      <Down
                        width={11}
                        className={
                          window.location.pathname === "/photoreview" ||
                          window.location.pathname === "/party/review" 
                            ? "downSvgActive"
                            : "downSvg"
                        }
                      />
                    </a>
                    <div
                      className="hs-sub-menu dropdown-menu"
                      aria-labelledby="reviewMegaMenu"
                    >
                      <a className="dropdown-item" href="/photoreview">
                        커플 후기
                      </a>
                      <a className="dropdown-item" href="/party/review">
                        파티 후기
                      </a>
                    </div>
                  </li>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "200px" }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="accountMegaMenu"
                      className={
                        window.location.pathname === "/consult/request"
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="/consult/request"
                      role="button"
                      aria-expanded="false"
                    >
                      상담 신청
                    </a>
                    <div
                      className="hs-sub-menu"
                      aria-labelledby="companyMegaMenu"
                    ></div>
                  </li>
                </div>
                {/* <li className="hs-has-sub-menu nav-item">
                  <a
                    id="pagesMegaMenu"
                    className="hs-mega-menu-invoker nav-link"
                    href="/consult/request"
                    role="button"
                    aria-expanded="false"
                  >
                    상담 신청
                  </a>
                </li> */}
              </ul>
            </div>
            <a
              id="accountMegaMenu"
              className="hs-mega-menu-invoker nav-link headerText headerLogin"
              href="/consult/request"
              role="button"
              aria-expanded="false"
              style={{ marginRight: "48px", visibility: "hidden" }}
            >
              로그인/회원가입
            </a>
          </nav>
        </div>
        <div className="mobileHeader">
          <nav
            ref={megaMenuRef1}
            className={
              subOpen
                ? "js-mega-menu navbar-nav-wrap containerMiniBorder"
                : "js-mega-menu navbar-nav-wrap"
            }
          >
            <div
              className="collapse navbar-collapse mobileHeader"
              id="navbarNavDropdown"
              style={{ order: "0" }}
            >
              <ul className="navbar-nav d-flex justify-content-center mobileHeaderIner pb-0 w-100">
                <div
                  className="d-flex justify-content-center"
                  style={{ flex: 1 }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="companyMegaMenu"
                      className={
                        window.location.pathname === "/introduce"
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="/introduce"
                      role="button"
                      aria-expanded="false"
                    >
                      우연은?
                    </a>
                    <div
                      className="hs-sub-menu"
                      aria-labelledby="companyMegaMenu"
                    ></div>
                  </li>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ flex: 1 }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="companyMegaMenu"
                      className={
                        window.location.pathname === "/problem" ||
                        window.location.pathname === "/whypage" ||
                        window.location.pathname === "/products/list"
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => setSubOpen(!subOpen)}
                    >
                      서비스소개
                      {subOpen ? (
                        <Up
                          width={11}
                          className={
                            window.location.pathname === "/problem" ||
                            window.location.pathname === "/whypage" ||
                            window.location.pathname === "/products/list"
                              ? "downSvgActive"
                              : "downSvg"
                          }
                        />
                      ) : (
                        <Down
                          width={11}
                          className={
                            window.location.pathname === "/problem" ||
                            window.location.pathname === "/whypage" ||
                            window.location.pathname === "/products/list"
                              ? "downSvgActive"
                              : "downSvg"
                          }
                        />
                      )}
                    </a>
                    <div
                      className="hs-sub-menu"
                      aria-labelledby="companyMegaMenu"
                    ></div>
                  </li>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ flex: 1 }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="reviewMegaMenu"
                      className={
                        window.location.pathname === "/photoreview" ||
                        window.location.pathname === "/party/review" 
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => setSubReviewOpen(!subReviewOpen)}
                    >
                      후기
                      {subReviewOpen ? (
                        <Up
                          width={11}
                          className={
                            window.location.pathname === "/photoreview" ||
                            window.location.pathname === "/party/review" 
                              ? "downSvgActive"
                              : "downSvg"
                          }
                        />
                      ) : (
                        <Down
                          width={11}
                          className={
                            window.location.pathname === "/photoreview" ||
                            window.location.pathname === "/party/review" 
                              ? "downSvgActive"
                              : "downSvg"
                          }
                        />
                      )}
                    </a>
                    <div
                      className="hs-sub-menu"
                      aria-labelledby="review"
                    ></div>
                  </li>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ flex: 1 }}
                >
                  <li className="hs-has-sub-menu nav-item">
                    <a
                      id="accountMegaMenu"
                      className={
                        window.location.pathname === "/consult/request"
                          ? "hs-mega-menu-invoker nav-link headerTextActive"
                          : "hs-mega-menu-invoker nav-link headerText"
                      }
                      href="/consult/request"
                      role="button"
                      aria-expanded="false"
                    >
                      상담 신청
                    </a>
                    <div
                      className="hs-sub-menu"
                      aria-labelledby="companyMegaMenu"
                    ></div>
                  </li>
                </div>
                {/* <li className="hs-has-sub-menu nav-item">
                  <a
                    id="pagesMegaMenu"
                    className="hs-mega-menu-invoker nav-link"
                    href="/consult/request"
                    role="button"
                    aria-expanded="false"
                  >
                    상담 신청
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
          {subOpen ? (
            <div className="w-100 d-flex align-items-center">
              <a
                id="companyMegaMenu"
                className={
                  window.location.pathname === "/problem"
                    ? "w-100 hs-mega-menu-invoker nav-link headerSubTextActive"
                    : "w-100 hs-mega-menu-invoker nav-link headerSubText"
                }
                href="/problem"
                role="button"
                aria-expanded="false"
              >
                기존의 문제점
              </a>
              <Vector width={6} height={16} />
              <a
                id="companyMegaMenu"
                className={
                  window.location.pathname === "/whypage"
                    ? "w-100 hs-mega-menu-invoker nav-link headerSubTextActive"
                    : "w-100 hs-mega-menu-invoker nav-link headerSubText"
                }
                href="/whypage"
                role="button"
                aria-expanded="false"
              >
                왜 우연인가?
              </a>
              <Vector width={6} height={16} />
              <a
                id="companyMegaMenu"
                className={
                  window.location.pathname === "/products/list"
                    ? "w-100 hs-mega-menu-invoker nav-link headerSubTextActive"
                    : "w-100 hs-mega-menu-invoker nav-link headerSubText"
                }
                href="/products/list"
                role="button"
                aria-expanded="false"
              >
                서비스 안내
              </a>
            </div>
          ) : null}
          {subReviewOpen ? (
            <div className="w-100 d-flex align-items-center">
              <a
                id="reviewMegaMenu"
                className={
                  window.location.pathname === "/photoreview"
                    ? "w-100 hs-mega-menu-invoker nav-link headerSubTextActive"
                    : "w-100 hs-mega-menu-invoker nav-link headerSubText"
                }
                href="/photoreview"
                role="button"
                aria-expanded="false"
              >
                커플 후기
              </a>
              <Vector width={6} height={16} />
              <a
                id="reviewMegaMenu"
                className={
                  window.location.pathname === "/party/review"
                    ? "w-100 hs-mega-menu-invoker nav-link headerSubTextActive"
                    : "w-100 hs-mega-menu-invoker nav-link headerSubText"
                }
                href="/party/review"
                role="button"
                aria-expanded="false"
              >
                파티 후기
              </a>
              
            </div>
          ) : null}
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  onToggleSubmenu: PropTypes.func.isRequired,
  
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
