import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { apiUrl } from "../../apis/request";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function WidgetSuccessPage() {
  const query = useQuery();
  const history = useHistory();
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function confirm() {
      try {
        const requestData = {
          orderId: query.get("orderId"),
          amount: query.get("amount"),
          paymentKey: query.get("paymentKey"),
        };

        const response = await fetch(`${apiUrl}/api/purchase/toss/complete`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const json = await response.json();

        if (!response.ok) {
          throw { message: json.message || "결제 확인 실패", code: json.code || "ERROR" };
        }

        setResponseData(json);
      } catch (err) {
        history.push(
          `/purchase/toss/fail?code=${err.code}&message=${encodeURIComponent(err.message)}`
        );
      } finally {
        setLoading(false);
      }
    }

    confirm();
  }, [history]);

  const orderId = query.get("orderId");
  const amount = query.get("amount");
  const paymentKey = query.get("paymentKey");

  return (
    <div style={styles.pageContainer}>
      <div style={styles.card}>
        {/* 상단 아이콘 */}
        <img
          src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png"
          alt="성공 아이콘"
          style={styles.successIcon}
        />
        {/* 결제 완료 텍스트 */}
        <h2 style={styles.title}>결제를 완료했어요</h2>

        {loading ? (
          <p style={styles.loadingText}>로딩 중...</p>
        ) : (
          <>
            {/* 결제 정보 */}
            <InfoRow label="결제금액" value={`${Number(amount).toLocaleString()}원`} />
            <InfoRow label="주문번호" value={orderId} />
            <InfoRow label="paymentKey" value={paymentKey} isLong />

            {/* 버튼 영역 */}
            <div style={styles.buttonContainer}>
              <ActionButton
                href="https://docs.tosspayments.com/guides/v2/payment-widget/integration"
                label="연동 문서"
                primary
              />
              <ActionButton
                href="https://discord.gg/A4fRFXQhRu"
                label="실시간 문의"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function InfoRow({ label, value, isLong }) {
  return (
    <div style={styles.infoRow}>
      <div style={styles.label}>{label}</div>
      <div style={{ ...styles.value, whiteSpace: isLong ? "normal" : "nowrap" }}>
        {value}
      </div>
    </div>
  );
}

function ActionButton({ href, label, primary }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
      <button style={primary ? styles.primaryButton : styles.secondaryButton}>{label}</button>
    </a>
  );
}

const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 0", // 화면 상하 여백을 줄이기
    backgroundColor: "#E8F3FF",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    width: "500px",
    textAlign: "center",
  },
  successIcon: {
    width: "80px",
  },
  title: {
    fontSize: "22px",
    fontWeight: "600",
    margin: "20px 0",
    color: "#333D4B",
  },
  loadingText: {
    fontSize: "16px",
    color: "#6B7684",
    marginTop: "20px",
  },
  infoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px",
    fontSize: "15px",
    color: "#4E5968",
  },
  label: {
    fontWeight: "bold",
    textAlign: "left",
  },
  value: {
    textAlign: "right",
    wordBreak: "break-word",
    maxWidth: "60%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "30px",
  },
  primaryButton: {
    backgroundColor: "#3182F6",
    color: "#fff",
    fontSize: "15px",
    fontWeight: "600",
    border: "none",
    borderRadius: "6px",
    padding: "12px 20px",
    cursor: "pointer",
    width: "180px",
  },
  secondaryButton: {
    backgroundColor: "#E8F3FF",
    color: "#1B64DA",
    fontSize: "15px",
    fontWeight: "600",
    border: "none",
    borderRadius: "6px",
    padding: "12px 20px",
    cursor: "pointer",
    width: "180px",
  },
};

