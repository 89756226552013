import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function FailPage() {
  const query = useQuery();
  const message = query.get("message") || "에러 메시지가 없습니다.";
  const code = query.get("code") || "UNKNOWN";

  return (
    <div style={styles.pageContainer}>
      <div style={styles.card}>
        {/* 에러 이미지 */}
        <img
          src="https://static.toss.im/lotties/error-spot-no-loop-space-apng.png"
          alt="에러 발생"
          style={styles.errorIcon}
        />
        {/* 타이틀 */}
        <h2 style={styles.title}>결제를 실패했어요</h2>

        {/* 에러 정보 */}
        <InfoRow label="에러 메시지" value={message} />
        <InfoRow label="에러 코드" value={code} />

        {/* 버튼 영역 */}
        <div style={styles.buttonContainer}>
          <ActionButton
            href="https://docs.tosspayments.com/guides/v2/payment-widget/integration"
            label="연동 문서"
            primary
          />
          <ActionButton
            href="https://discord.gg/A4fRFXQhRu"
            label="실시간 문의"
          />
        </div>
      </div>
    </div>
  );
}

// ✅ 에러 메시지 표시 영역
function InfoRow({ label, value }) {
  return (
    <div style={styles.infoRow}>
      <div style={styles.label}>{label}</div>
      <div style={styles.value}>{value}</div>
    </div>
  );
}

// ✅ 버튼 컴포넌트
function ActionButton({ href, label, primary }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
      <button style={primary ? styles.primaryButton : styles.secondaryButton}>{label}</button>
    </a>
  );
}

// ✅ 스타일 정의
const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 0", // 화면 상하 여백을 줄이기
    backgroundColor: "#E8F3FF",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    width: "500px",
    textAlign: "center",
  },
  errorIcon: {
    width: "80px",
  },
  title: {
    fontSize: "22px",
    fontWeight: "600",
    margin: "20px 0",
    color: "#333D4B",
  },
  infoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px",
    fontSize: "15px",
    color: "#4E5968",
  },
  label: {
    fontWeight: "bold",
    textAlign: "left",
  },
  value: {
    textAlign: "right",
    wordBreak: "break-word",
    maxWidth: "60%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "30px",
  },
  primaryButton: {
    backgroundColor: "#3182F6",
    color: "#fff",
    fontSize: "15px",
    fontWeight: "600",
    border: "none",
    borderRadius: "6px",
    padding: "12px 20px",
    cursor: "pointer",
    width: "180px",
  },
  secondaryButton: {
    backgroundColor: "#E8F3FF",
    color: "#1B64DA",
    fontSize: "15px",
    fontWeight: "600",
    border: "none",
    borderRadius: "6px",
    padding: "12px 20px",
    cursor: "pointer",
    width: "180px",
  },
};

