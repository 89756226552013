import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  adminRequestPost,
  adminUserPut,
  homeRequestGet,
  requestGet,
} from "../../apis/request";
import "./purcahse.scss";

function PurchasewaitToss(props) {
  const history = useHistory();

  const callApi = async (params) => {
    const paymentKey = params.get("paymentKey");
    let message="결제 실패 하였습니다."
    if (paymentKey) {
      try {
        let body = {
          orderId: params.get("orderId"),
          amount: params.get("amount"),
          paymentKey: params.get("paymentKey"),
        };
        await adminUserPut(`/api/purchase/toss/complete`, body);
        message="결제 성공 하였습니다."
      } catch (error) {
        console.log(error);
        message="결제 실패 하였습니다."
      }
    } else {
      message="결제 실패 하였습니다."
    }
    alert(message)
    // history.replace("/dashboard");
    window.location.replace("/dashboard")
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search.split("?")[1]);
    callApi(params);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "#fff",
          zIndex: 55555,
        }}
      />
    </React.Fragment>
  );
}

export default PurchasewaitToss;
