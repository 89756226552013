import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import {
  adminRequestPost,
  adminUserPut,
  apiUrl,
  homeRequestGet,
  requestGet,
} from "../../apis/request";
import "./purcahse.scss";
const clientKey = process.env.REACT_APP_TOSS_WIDGET_CLIENT_KEY;

//lch1721 현재 결제 페이지 
function Purchasecopy(props) {
  const [productId, setProductId] = useState("");
  const [stateName, setStateName] = useState("");
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState("");
  const [userCode, setUserCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailEnd, setEmailEnd] = useState("");
  const [bracketContent, setBracketContent] = useState("");
  const [otherContent, setOtherContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isCheck, setisCheck] = useState(true);
  const [isCheck1, setisCheck1] = useState(false);
  const [isCheck2, setisCheck2] = useState(false);
  const [talkFirst, setTalkFirst] = useState(false);
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState(null);
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [terms, setTerms] = useState("");
  const [onlyTerm, setOnlyTerm] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const [widgetMethod, setWidgetMethod] = useState(null);
  const [widgetAggrement, setWidgetAggrement] = useState(null);
  const { id, uuid } = useParams();
  const { IMP } = window;
// 테스트
  const normalTerm = " ※ 사업자(을)의 책임 있는 사유로 계약해제 및 해지 시 환급기준<br />" +
"<br />" +
"      1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된 경우 : 가입비 환급 및 가입비의 10% 배상<br />" +
"      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 가입비 환급 및 가입비의 15% 배상<br />" +
"      3. 만남일자 확정 후에 해지된 경우 : 가입비 환급 및 가입비의 20% 배상<br />" +
"      4. 1회 만남 후 해지된 경우 : (횟수제) 가입비*(잔여 횟수/총횟수) +가입비의 20% 환급<br />" +
"<br />" +
"<br />" +
"※ 사업자(을)의 책임 없는 사유로 계약해제 및 해지 시 환급기준<br />" +
"<br />" +
"      1. 회원가입계약 성립 후 이성 프로필 제공 전에 해지된 경우 : 회원가입비의 90% 환급<br />" +
"      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 회원가입비의 85% 환급 – 소개 비용<br />" +
"      3. 만남일자 확정 후에 해지된 경우 : 회원가입비의 80% × (잔여횟수/총횟수) 환급 – 소개 비용<br />" +
"      4. 1회 만남 후 해지된 경우 : 회원가입비의 80% × (잔여횟수/총횟수) 환급 – 소개 비용<br />" +
"<br />" +
"<br />" +
" ※ 횟수제의 경우 이성 정보(프로필) 제공 후 3개월마다 가입비의 5%씩 소개 비용이 차감되며 기간 내 횟수를 모두 차감하는 경우 소개 비용은 발생하지 않습니다.<br />" +
"<br />" +
"0-3개월 : 5%, 4-6개월  :10% , 7-9개월 : 15%, 10-12개월  :20%" ;

//   const normalTerm = " ※ 사업자(을)의 책임 있는 사유로 계약해제 및 해지 시 환급기준<br />" +
// "<br />" +
// "      1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된 경우 : 가입비 환급 및 가입비의 10% 배상<br />" +
// "      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 가입비 환급 및 가입비의 15% 배상<br />" +
// "      3. 만남일자 확정 후에 해지된 경우 : 가입비 환급 및 가입비의 20% 배상<br />" +
// "      4. 1회 만남 후 해지된 경우 : (횟수제) 가입비*(잔여 횟수/총횟수) +가입비의 20% 환급<br />" +
// "<br />" +
// "<br />" +
// "※ 사업자(을)의 책임 없는 사유로 계약해제 및 해지 시 환급기준<br />" +
// "<br />" +
// "      1. 회원가입계약 성립 후 이성 프로필 제공 전에 해지된 경우 : 회원가입비의 90% 환급<br />" +
// "      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 회원가입비의 85% 환급 – 소개 비용<br />" +
// "      3. 만남일자 확정 후에 해지된 경우 : 회원가입비의 80% × (잔여횟수/총횟수) 환급 – 소개 비용<br />" +
// "      4. 1회 만남 후 해지된 경우 : 회원가입비의 80% × (잔여횟수/총횟수) 환급 – 소개 비용<br />" +
// "<br />" +
// "<br />" +
// "※ 횟수제의 경우 이성 정보(프로필) 제공 후 3개월마다 가입비의 5%씩 소개 비용이 차감되며 기간 내 횟수를 모두 차감하는 경우 소개 비용은 발생하지 않습니다.<br />" +
// "<br />" +
// "0-3개월 : 5%, 4-6개월 : 10%, 7-9개월 : 15%, 10-12개월 : 20%" ;


  const subscribeTerm = "※ 사업자(을)의 책임 있는 사유로 계약해제 및 해지 시 환급기준 <br />" + 
"<br />" + 
"       1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된 경우 : 가입비 및 가입비의 10% 환급<br />" + 
"       2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 가입비 및 가입비의 15% 환급<br />" + 
"       3. 만남일자 확정 후에 해지된 경우 : 가입비 및 가입비의 20% 환급<br />" + 
"       4. 1회 만남 후 해지된 경우 : (기간제) 가입비*[잔여일수/총일수] + 가입비의 20% 환급<br />" + 
"<br />" + 
"<br />" + 
"     ※ 사업자(을)의 책임 없는 사유로 계약해제 및 해지 시 환급기준" + 
"<br />" + 
"      1. 회원가입계약 성립 후 이성 프로필 제공 전에 해지된 경우 : 회원가입비의 90% 환급<br />" + 
"      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 회원가입비의 85% * (잔여일수/총일수) 환급<br />" + 
"      3. 만남일자 확정 후에 해지된 경우 : 회원가입비의 80% * (잔여일수/총일수) 환급<br />" + 
"      4. 1회 만남 후 해지된 경우 : 회원가입비의 80%*(잔여일수/총일수) - 만남 횟수비용 (회당 40만원) 차감 후 환급<br />"+
"<br />" + 
" ※ 야외 스냅 촬영 지원을 받은 경우, 3개월 이내 환불 시 촬영 지원금을 배상해야 합니다."
;


// const subscribeTerm = "※ 사업자(을)의 책임 있는 사유로 계약해제 및 해지 시 환급기준<br />" + 
// "<br />" + 
// "       1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된 경우 : 가입비 및 가입비의 10% 환급<br />" + 
// "       2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 가입비 및 가입비의 15% 환급<br />" + 
// "       3. 만남일자 확정 후에 해지된 경우 : 가입비 및 가입비의 20% 환급<br />" + 
// "       4. 1회 만남 후 해지된 경우 : (기간제) 가입비*[잔여일수/총일수] + 가입비의 20% 환급<br />" + 
// "<br />" + 
// "<br />" + 
// "     ※ 사업자(을)의 책임 없는 사유로 계약해제 및 해지 시 환급기준" + 
// "<br />" + 
// "      1. 회원가입계약 성립 후 이성 프로필 제공 전에 해지된 경우 : 회원가입비의 90% 환급<br />" + 
// "      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 회원가입비의 85% * (잔여일수/총일수) 환급<br />" + 
// "      3. 만남일자 확정 후에 해지된 경우 : 회원가입비의 80% * (잔여일수/총일수) 환급<br />" + 
// "      4. 1회 만남 후 해지된 경우 : 회원가입비의 80% * (잔여일수/총일수) 환급<br />"+
// "<br />" + 
// " ※ 야외 스냅 촬영 지원을 받은 경우, 3개월 이내 환불 시 촬영 지원금을 배상해야 합니다."
// ;


// 상품 설명으로 대체하려고 하였는데 수기 입력시에는 문제가 될것 같아서 고정 문구 처리를 진행하였습니다.
const marriageFeeTerm="결혼을 진심으로 축하드립니다!<br />"+"우연은 늘 회원님의 행복을 빌겠습니다 :)"

const partyTerm="* 유의사항*<br />" +
"<br />" +
"- 매니저에게 사전 공유 없이 10분 이상 지각 시 입장이 제한될 수 있습니다.<br />" +
"<br />" +
"- 주차는 제공되지 않으니 인근 유료 주차장을 이용해 주시기 바랍니다.<br />" +
"(주차장이 매우 혼잡할 수 있으니 가급적 대중교통 이용을 권장합니다.)<br />" +
"<br />" +
"[프로그램 안내]<br />" +
"1) 디너 코스 요리 (와인 & 음료 제공)<br />" +
"2) 아이스 브레이킹<br />" +
"3) 로테이션 대화<br />" +
"4) 경품 추첨<br />" +
"<br />" +
"※ 유튜버 김달님께서 참석 예정입니다.<br />" +
"※ 프로그램은 주최 측 상황에 따라 변경될 수 있습니다.<br />" +
"※ 인원수에 맞춰 음식을 준비하므로, 7일 전까지 불참 여부를 반드시 알려주셔야 합니다.<br />" +
"7일 전까지 사전 통보 없이 불참 시 노쇼 비용(매칭권 차감 또는 참석 비용 환불 불가)이 <br />" +
"발생할 수 있으니 유의 바랍니다 .";


  useEffect(() => {
    if (location.state) {
      setProductId(location?.state?.productId);
      setStateName(location?.state?.name);
      setAmount(location?.state?.amount);
      setDesc(location?.state?.desc);
    }
  }, [location]);

  useEffect(() => {
    if (desc) {
      const match = desc.match(/\[(.*?)\]/);
      const bracketContent = match
        ? match[0].replace("[", "").replace("]", "")
        : ""; // 대괄호 내용
      // const otherContent = desc
      //   .replace(bracketContent, "")
      //   .trim()
      //   .replace("[", "")
      //   .replace("]", "");
      let otherContent = desc
              .replace(bracketContent, "")
              .trim()
              .replace("[", "")
              .replace("]", "");
      if(otherContent === "-"){
        otherContent = "";
      }
      setBracketContent(bracketContent);
      setOtherContent(otherContent);
    }
  }, [desc]);

  const callApi = async () => {
    const response = await requestGet(`/api/product/list`);
    setData(response?.data);
  };


  useEffect(() => {
    if (id) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].id === parseInt(id)) {
          setProductData(data[i]);
        }
      }
    }
  }, [id, data]);



  useEffect(() => {
    if (productData) {
      setProductId(productData?.id);
      setStateName(productData?.name);
      setAmount(productData?.price);
      setDesc(productData?.desc);
      waitApi();
    }
  }, [productData]);

  const callInfp = useCallback(async () => {
    setTerms(normalTerm);
    try {
      const response = await requestGet(`/api/purchase/info/${uuid}`);
      setName(response?.data?.name);
      setPhone(response?.data?.phone);
      setUserId(response?.data?.userId);
      //0630 이창희 uuid를 통한 호출해서 데이터를 세팅한다.
      if (
        response?.data?.productId &&
        response?.data?.productName &&
        response?.data?.price
      ) {
        let data = {
          id: response?.data?.productId,
          name: response?.data?.productName,
          price: response?.data?.price,
          desc: response?.data?.desc,
          productType : response?.data?.productType,
        };
        if("subscribe" === response?.data?.productType){
          setTerms(subscribeTerm);
        }
        if ("marriageFee" ===response?.data?.productType){
          setOnlyTerm(marriageFeeTerm)
        }
        if ("party" ===response?.data?.productType){
          setOnlyTerm(partyTerm)
        }
        // setProductId(data.id);
        // setStateName(data.name);
        // setAmount(data.price);
        setTalkFirst(true);
        setProductData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      callInfp();
    }
  }, [callInfp, uuid]);




  const requestPay = (data) => {
    if (widgets) {
      console.log('requestPay input data :',data)
      console.log(name,phone)
      try {
        const currentHost = window.location.origin;
        widgets.requestPayment({
            orderId: data.orderId, // 고유 주문 번호
            orderName:stateName,
            // successUrl: `${currentHost}/purchase/success`, // 결제 요청이 성공하면 리다이렉트되는 URL
            // failUrl:  `${currentHost}/purchase/fail`, // 결제 요청이 실패하면 리다이렉트되는 URL
            successUrl: `${currentHost}/purchasewait`, // 결제 요청이 성공하면 리다이렉트되는 URL
            failUrl:  `${currentHost}/purchasewait`, // 결제 요청이 실패하면 리다이렉트되는 URL
            customerEmail:`${email}@${emailEnd}`,
            customerName:name,
            customerMobilePhone: phone,
          });
      } catch (error) {
        console.error("Error during payment initiation:", error);
      }
    } else {
      console.error(
        "toss widgets not activate"
      );
    }
  };

  const preparePay = () => {

    
    if (isCheck&& name && phone && email && emailEnd) {
      fetch(`${apiUrl}/api/purchase/toss/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productId: productId,
          orderer: name,
          phone: phone,
          userId: userId,
          email: `${email}@${emailEnd}`,
        }),
      })
        .then((response) => response.json())
        .then(async(data) => {
          return requestPay(data);
        })
        .catch((error) => {
          console.log(error);
          alert("구매 실패");
        });
    }else if (!isCheck) {
      return alert("구매 이용 약관에 동의해 주세요.");
    }  else if (!name) {
      return alert("주문자 입력은 필수 입니다.");
    } else if (!phone) {
      return alert("핸드폰 번호 입력은 필수 입니다.");
    } else if (!email) {
      return alert("이메일 입력은 필수 입니다.");
    } else if (!emailEnd) {
      return alert("이메일 입력은 필수 입니다.");
    }
  };


  const onEmailEnd = (e) => {
    setEmailEnd(e.target.value);
  };

  useEffect(() => {
    document.title = "결제 | 우리의 인연";
  }, []);

  //토스  결제 정의 부분
// 임시 문서키 - 실제 키사용시 env에 정의하여 사용할것
  useEffect(() => {
    async function fetchPaymentWidgets() {
      try {
        const tossPayments = await loadTossPayments(clientKey);
        // 우선은 익명 처리로 근데 우리가 회원 결제를 하는건가?
        // 회원 결제
        // @docs https://docs.tosspayments.com/sdk/v2/js#tosspaymentswidgets
        // const widgets = tossPayments.widgets({
        //   customerKey,
        // });
        // 비회원 결제
        const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });

        setWidgets(widgets);
        console.log('fetchPaymentWidgets worked')
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    }

    fetchPaymentWidgets();
  }, [clientKey]);



  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        setReady(false);
        return;
      }

      const methodEl = document.getElementById("payment-method");
      const agreementEl = document.getElementById("agreement");
      if (!methodEl || !agreementEl) return;
      try{
        if (!amount || typeof amount !=='number'){
          return
        }

        await widgets.setAmount({
          currency: "KRW",
          value:amount
        });
        

        // if (){

        // }
        let paymethod=null,aggrement=null;
        const [paymentMethodResult, agreementResult] = await Promise.allSettled([
          widgets.renderPaymentMethods({
            selector: "#payment-method",
            variantKey: "DEFAULT",
          }),
          widgets.renderAgreement({
            selector: "#agreement",
            variantKey: "AGREEMENT",
          })
        ]);
    
        // 결과 처리 (성공 또는 실패)
        if (paymentMethodResult.status === 'fulfilled') {
          paymethod = paymentMethodResult.value;
          console.log('결제 방법 위젯 렌더링 성공:', paymethod);
        } else {
          console.error('결제 방법 위젯 렌더링 실패:', paymentMethodResult.reason);
        }
    
        if (agreementResult.status === 'fulfilled') {
          aggrement = agreementResult.value;
          console.log('동의 위젯 렌더링 성공:', aggrement);
          aggrement.on('agreementStatusChange', agreementStatus => {
            if (agreementStatus.agreedRequiredTerms) {
              setisCheck(true)
            } else {
              setisCheck(false)
            }
          })
        } else {
          console.error('동의 위젯 렌더링 실패:', agreementResult.reason);
        }
    
        // 상태 업데이트
        setWidgetMethod(paymethod);
        setWidgetAggrement(aggrement);
    

        setWidgetMethod(paymethod)
        setWidgetAggrement(aggrement)
        setReady(true);
      
      } catch (error) {
        console.error("Error rendering widgets:", error);
      }



    }

    renderPaymentWidgets();
  }, [widgets,amount]);

  const waitApi = () => {
    if (talkFirst) {
      if (
        window.confirm(`
        이름 : ${productData.name}
        가격 : ${String(productData.price).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}원
        상품을 안내받으셨습니다.
        계속 진행 하시겠습니까?`)
      ) {
        setTalkFirst(false);
      } else {
        window.location.href = "https://www.coincidence.co.kr/";
      }
    }
  };
  return (
    <React.Fragment>

      <div className="page-content customWrap">
        <Container className="purchase-container">
          <Row>
            <Col lg="8" className="purTableWrap">
            <Row>
              <div className=" h-100">
              <div className="">
                  <div id="payment-method" />
                  <div id="agreement" />
              </div>
              </div>
              </Row>
              <Row>
                <h1 className="purTitle">주문자 정보</h1>
                <Col xs="12">
                  <table className="purCustomTable">
                    <tbody>
                      <tr className="purCustomTableTr">
                        <td className="purCustomTableTdName">주문자</td>
                        <td className="purCustomTableTdInputWrap">
                          <input
                            type="text"
                            className="purCustomTableTdInput"
                            placeholder="주문자"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </td>
                      </tr>
                      <tr className="purCustomTableTr">
                        <td className="purCustomTableTdName">핸드폰 번호</td>
                        <td className="purCustomTableTdInputWrap">
                          <input
                            type="text"
                            className="purCustomTableTdInput"
                            placeholder="핸드폰 번호"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </td>
                      </tr>
                      <tr className="purCustomTableTr">
                        <td className="purCustomTableTdName">이메일</td>
                        <td className="purCustomTableTdInputWrap">
                          <Row className="align-items-center">
                            <Col className="pe-0">
                              <input
                                type="text"
                                className="purCustomTableTdInputEmail w-100"
                                placeholder="이메일"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Col>
                            <span className="purCustomTableTdInput2 w-auto p-0">
                              @
                            </span>
                            <Col className="ps-0">
                              <input
                                type="text"
                                className="purCustomTableTdInputEmail w-100"
                                value={emailEnd}
                                onChange={(e) => setEmailEnd(e.target.value)}
                              />
                            </Col>
                          </Row>
                          <Row className="align-items-center">
                            <Col>
                              <select
                                className="purCustomTableTdSelect"
                                onChange={onEmailEnd}
                              >
                                <option value="">직접입력</option>
                                <option value="naver.com">@naver.com</option>
                                <option value="gmail.com">@gmail.com</option>
                                <option value="daum.net">@daum.net</option>
                                <option value="hanmail.net">
                                  @hanmail.net
                                </option>
                                <option value="yahoo.com">@yahoo.com</option>
                                <option value="outlook.com">
                                  @outlook.com
                                </option>
                                <option value="nate.com">@nate.com</option>
                                <option value="kakao.com">@kakao.com</option>
                              </select>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>


            </Col>
            <Col lg="4" className="furRightWrap">
              <div className="furRight">
                <div className="furRightInWrap">
                  <Row>
                    <Col className="furNameText">{stateName}</Col>
                  </Row>
                  <Row className="purcollapWrap">
                    <Collapse isOpen={true} className="purcard">
                      <Card>
                        <CardBody
                          style={{ maxHeight: "260px", overflow: "auto" }}
                        >
                          {
                            onlyTerm !== null ? (
                              <>
                  
                                {onlyTerm.split('<br />').map((line, index) => (
                                  <span key={index}>
                                    {line}
                                    <br />
                                  </span>
                                ))}
                              </>
                            ) : (
                              <>
                                {/* [제품설명]
                                <br />
                                {otherContent}
                                <br />
                                {bracketContent}
                                <br />
                                <br /> */}
                                [환불규정]
                                <br />
                                {terms && terms.split('<br />').map((line, index) => (
                                  <span key={index}>
                                    {line}
                                    <br />
                                  </span>
                                ))}
                              </>
                            )
                          }



                        </CardBody>
                      </Card>
                    </Collapse>
                  </Row>
                  <Row className="furRightMain">
                    <Col>
                      <span className="furRightMainTitle">총 결제 금액</span>
                      <span className="furRightVAT">(VAT 포함)</span>
                    </Col>
                    <Col className="text-end">
                      <span className="furRightPrice">
                        {amount
                          ? String(amount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) + "원"
                          : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row className="purAgree">
                    <Col>위 내용을 확인하였고 결제에 동의합니다.</Col>
                  </Row>
                  <Row>
                    <button
                      className="purBtn d-flex align-items-center justify-content-center blackColor"
                      onClick={() => preparePay()}
                    >
                      결제하기
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  );
}

export default Purchasecopy;
